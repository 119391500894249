import { Injectable } from '@angular/core';
import { concatMap, map, take } from 'rxjs/operators';
import { getSiteLanguage } from '../store/selectors/site.selectors';
import { getUserLanguage } from '../store/selectors/user.selectors';
import * as UIActions from '../store/actions/ui.actions';
import * as WishListActions from '../../wish-list/store/wish-list.actions';
import { Observable, of } from 'rxjs';
import { LocaleType } from '../models/locales.models';
import { AppStoreService } from '../store/app-store.service';

@Injectable({ providedIn: 'root' })
export class StoreService {
    constructor(private appStoreService: AppStoreService) {}

    public getCurrentSelectorState<Output>(
        selector: any,
        props: any = null
    ): Promise<Output> {
        if (props) {
            return this.appStoreService.store
                .select(selector, props)
                .pipe(take(1))
                .toPromise();
        } else {
            return this.appStoreService.store
                .select(selector)
                .pipe(take(1))
                .toPromise();
        }
    }

    public toogleLoader(toogle: boolean): void {
        this.appStoreService.store.dispatch(
            UIActions.toggleGlobalSpinner({ toggle: toogle })
        );
    }

    public toggleTrekToWishList(toogle: boolean, trek_id: string): void {
        if (toogle) {
            this.appStoreService.store.dispatch(
                WishListActions.addTrek({ trek_id })
            );
        } else {
            this.appStoreService.store.dispatch(
                WishListActions.removeTrek({ trek_id })
            );
        }
    }

    public getRequestLang(): string {
        const user = this.appStoreService.state.userState.user;
        const siteLang = this.appStoreService.state.siteState.siteLanguage;
        return user?.settings?.locale || siteLang || 'en';
    }

    getRequestLang$(): Observable<LocaleType> {
        return this.appStoreService.store.select(getUserLanguage).pipe(
            take(1),
            concatMap((language) => {
                if (language) {
                    return of(language);
                } else {
                    return this.appStoreService.store.select(getSiteLanguage);
                }
            }),
            take(1),
            map((lang) => lang || 'en')
        );
    }

    checkIfLangAreEqual(textLang: LocaleType, userLang: LocaleType) {
        if (!textLang) {
            return false;
        }
        if (textLang === ('und' as LocaleType)) {
            return false;
        }
        const hebLangCode = ['he', 'iw'];
        if (hebLangCode.includes(textLang) && hebLangCode.includes(userLang)) {
            return false;
        }
        return !(textLang === userLang);
    }
}
